<template>
    <div class="page">
        <div class="banner">
            <img class="bg" src="@/assets/images/bg.png" alt="">
            <div class="box" data-flex="dir:top cross:center main:center">
                <p>欢迎使用</p>
                <div class="input" data-flex="cross:center main:center">
                    <van-field v-model="formData.kw" placeholder="请输入关键字" />
                    <div class="icon" data-flex="cross:center main:center" @click="onSearch">
                        <img src="../../assets/images/search.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="tab_box" data-flex="cross:center main:justify">
            <van-uploader v-model="fileList" :preview-image="false" :after-read="afterRead" capture="camera">
                <div class="list" data-flex="dir:top cross:center main:center">
                    <img src="../../assets/images/i-01.png" alt="">
                    <p>拍照识图</p>
                </div>
            </van-uploader>
            <van-uploader v-model="fileList" :preview-image="false" :after-read="afterRead">
                <div class="list" data-flex="dir:top cross:center main:center">
                    <img src="../../assets/images/i-02.png" alt="">
                    <p>图片识图</p>
                </div>
            </van-uploader>
        </div>
        <FootBox />
        
        <TabsBox ref="tabsBox" :active="0" />
    </div>
</template>

<script>
import mix1 from '@/mixins/page'
import { uiShowLoading, uiHideLoading, uiAlert, uiConfirm } from '@/utils/ui'
import { delayAsync } from '@/utils/common'
import { Field, Popup, Button, NumberKeyboard, Swipe, SwipeItem, Lazyload, Card, Tabbar, TabbarItem, List, Toast, Uploader } from 'vant'
import { localStorageGet, localStorageSet, localStorageRemove } from '@/utils/storage'
import { cacheKeys } from '../../config/CacheKeys'
import MgPopup from '@/components/common/mg-popup'
import TabsBox from '@/components/common/tabs'
import FootBox from '@/components/common/foot'
import { userInfo } from 'os'

import pako from 'pako'
import wxShare from '@/utils/wxShare'

export default {
    mixins: [mix1],
    name: 'HomeIndex',
    components: {
        MgPopup,
        TabsBox,
        FootBox,
        [Button.name]: Button,
        [Field.name]: Field,
        [Popup.name]: Popup,
        NumberKeyboard,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [Lazyload.name]: Lazyload,
        [Card.name]: Card,
        [Tabbar.name]: Tabbar,
        [TabbarItem.name]: TabbarItem,
        [List.name]: List,
        [Uploader.name]: Uploader
    },
    data() {
        return {
            fileList: [],
            formData: {
                kw: '',
            },
        }
    },
    computed: {

    },
    methods: {
        onSearch(){
            let kw = this.formData.kw
            if(this.isEmpty(kw)){
                Toast('请输入关键词搜索')
                return
            }
            
            this.$router.push('/goods/search?kw=' + kw)
        },
        async afterRead(file){
            this.fileList = []
            
            // 小于4m不压缩
            let size = 4096000
            if(file.file.size < size){
                this.upData(file.content)
                return
            }

            // 压缩
            this.compressImg(file.content, 0.9)
        },
        async compressImg (base64, multiple) {
            // 第一个参数就是需要加密的base65，
            // 第二个是压缩系数 0-1，
            // 第三个压缩后的回调 用来获取处理后的 base64
            if (!base64) {
                return
            }
            const _this = this
            const length = base64.length / 1024
            // 压缩方法
            let newImage = new Image()
            let quality = 0.9   // 压缩系数0-1之间
            newImage.src = base64
            newImage.setAttribute('crossOrigin', 'Anonymous') // url为外域时需要
            let imgWidth,
            imgHeight
            let w = undefined
            newImage.onload = function () {
                // 这里面的 this 指向 newImage
                // 通过改变图片宽高来实现压缩
                w = this.width * multiple
                imgWidth = this.width
                imgHeight = this.height
                let canvas = document.createElement('canvas')
                let ctx = canvas.getContext('2d')
                if (Math.max(imgWidth, imgHeight) > w) {
                if (imgWidth > imgHeight) {
                    canvas.width = w
                    // 等比例缩小
                    canvas.height = w * (imgHeight / imgWidth)
                } else {
                    canvas.height = w
                    // 等比例缩小
                    canvas.width = w * (imgWidth / imgHeight)
                }
                } else {
                canvas.width = imgWidth
                canvas.height = imgHeight
                // quality 设置转换为base64编码后图片的质量，取值范围为0-1  没什么压缩效果
                // 还是得通过设置 canvas 的宽高来压缩
                quality = 0.8
                }
                ctx.clearRect(0, 0, canvas.width, canvas.height)
                ctx.drawImage(this, 0, 0, canvas.width, canvas.height) //  // 这里面的 this 指向 newImage
                let smallBase64 = canvas.toDataURL('image/jpeg', quality) // 压缩语句
                // 如想确保图片压缩到自己想要的尺寸,如要求在50-150kb之间，请加以下语句，quality初始值根据情况自定
                // while (smallBase64.length / 1024 > 150) {
                // quality -= 0.01;
                // smallBase64 = canvas.toDataURL("image/jpeg", quality);
                // }
                // 防止最后一次压缩低于最低尺寸，只要quality递减合理，无需考虑
                // while (smallBase64.length / 1024 < 50) {
                // quality += 0.001;
                // smallBase64 = canvas.toDataURL("image/jpeg", quality);
                // }

                // 必须通过回调函数返回，否则无法及时拿到该值，回调函数异步执行
                console.log(`压缩前：${length}KB`)
                console.log(`压缩后：${smallBase64.length / 1024} KB`)
                console.log('smallBase64', smallBase64)
                _this.upData(smallBase64)
            }
        },
        async upData(smallBase64){
            let content = smallBase64.split(',').splice(1, 1)
            if(this.isEmpty(content[0])){
                Toast('上传图片失败')
                return
            }

            uiShowLoading()
            await delayAsync(500)

            const promise = this.apiPost('image.save', {img: content[0]})

            let apiResult = null

            try {
                apiResult = await promise
            } catch (ex) {
                apiResult = null
            }

            uiHideLoading()

            if (this.hasException(apiResult)) {
                return
            }

            this.$router.push('/goods/searchRecognition?id=' + apiResult.obj.id)
        }
    },
    mounted() {
    },
    created() {
    }
}
</script>
<style lang="less" scoped>

.page {
    background: #fff;
    padding-bottom: 120px;
    position: relative;
    // height: calc(100vh);
    box-sizing: border-box;


    .banner {
        width: 750px;
        height: 470px;
        position: relative;
        .bg {
            width: 750px;
            height: 470px;
            position: absolute;
            top: 0;
            left: 0;
        }
        .box {
            position: absolute;
            z-index: 1000;
            width: 100%;
            height: 470px;
            p {
                font-size: 40px;
                color: #fff;
                font-weight: bold;
                text-align: center;
            }
            .input {
                margin-top: 50px;
                .van-cell {
                    width: 530px;
                    height: 88px;
                    line-height: 88px;
                    padding:0 10px;
                    border-radius: 10px 0 0 10px;
                    font-size: 14px;
                }
                .van-cell::after {
                    border: none;
                }
                .icon{
                    width: 120px;
                    height: 88px;
                    background: #F7A400;
                    border-radius: 0px 10px 10px 0px;
                    img {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }

    .tab_box {
        padding: 30px 30px;
        box-sizing: border-box;
        .list {
            width: 330px;
            height: 270px;
            box-shadow: 0px 0px 10px 1px rgba(64,63,63,0.16);
            border-radius: 15px;
            p {
                font-size: 14px;
                color: #333;
                margin-top: 20px;
            }
            img{
                width: 90px;
                height: 90px;
            }
        }
    }
}
</style>