<template>
  <van-tabbar v-model="active">
      <van-tabbar v-model="active">
          <van-tabbar-item @click="tabsTo(1)">
              <span>首页</span>
              <template #icon="props">
                  <img v-if="props.active" src="../../assets/images/t-11.png" alt="">
                  <img v-else src="../../assets/images/t-01.png" alt="">
              </template>
          </van-tabbar-item>
          <van-tabbar-item icon="search" @click="tabsTo(2)">
              <span>全部商品</span>
              <template #icon="props">
                  <img v-if="props.active" src="../../assets/images/t-22.png" alt="">
                  <img v-else src="../../assets/images/t-02.png" alt="">
              </template>
          </van-tabbar-item>
      </van-tabbar>
  </van-tabbar>
</template>

<script>
import customEvents from '@/config/custom_events'
import bus from '@/libs/bus'
import { getRandomString } from '@/utils/string'
import { isNumber } from '@/utils/assert'
import { getWindowHeight } from '@/utils/common'
import { localStorageGet, localStorageSet, localStorageRemove } from '@/utils/storage'
import {  Tabbar, TabbarItem } from 'vant'


export default {
  name: 'tabs',
  props: ['active'],
  components: {
      [Tabbar.name]: Tabbar,
      [TabbarItem.name]: TabbarItem,
  },
  data() {
    return {
    }
  },
  methods: {

    tabsTo(type) {
          if (type == 1) {
              this.$router.push('/')
          } else {
              this.$router.push('/goods/list')
          }
      },

  },
  created() {

  },
  mounted() {
  }
}
</script>

<style lang="less">
  .van-tabbar-item--active {
      color: #205CFF;
  }

  .van-tabbar--fixed {
      height: 100px;
  }

  .van-hairline--top-bottom {
      padding: 0 50px;
      box-sizing: border-box;
  }

  .van-swipe__indicator {
      width: 10px;
      height: 10px;
  }

</style>