<template>
  <div class="foot" data-flex="cross:center main:center" @click="toUrl">
    <img src="../../assets/images/icon.png" alt="">
    <div>粤ICP备2024195512号</div>
  </div>
</template>

<script>
import customEvents from '@/config/custom_events'
import bus from '@/libs/bus'
import { getRandomString } from '@/utils/string'
import { isNumber } from '@/utils/assert'
import { getWindowHeight } from '@/utils/common'
import { localStorageGet, localStorageSet, localStorageRemove } from '@/utils/storage'


export default {
  name: 'HeadKf',
  data() {
    return {
    }
  },
  methods: {
    toUrl() {
      window.location.href = 'https://beian.miit.gov.cn/#/Integrated/index'
    },
  },
  created() {
  },
  mounted() {
  }
}
</script>

<style lang="less" scoped>
.foot {
  // position: absolute;
  // bottom: 20px;
  margin: 0 auto;
  width: 100%;
  img{
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  div{
    font-size: 12px;
    color: #333;
  }
}
</style>